<template>
  <div class="pageContol listWrap templateList addlectuer">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" >大屏管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">大屏设置</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="addlist" style="width: 90%; margin-left: 50px">
              <div>
                <div class="form-box">
                  <el-form
                    ref="baseform"
                    label-width="7rem"
                    class="form"
                    :rules="rules"
                    :model="baseform"
                    :disabled="disabled"
                  >
                    <el-form-item label="机构名称" prop="teacherName">
                      <el-input
                        v-model="$route.query.compName"
                        style="width: 80%"
                        disabled
                      ></el-input>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <div>
                <h3>
                  <span>基础配置</span>
                </h3>
                <div class="form-box">
                  <el-form
                    ref="baseform"
                    label-width="8rem"
                    class="form"
                    :rules="rules"
                    :model="baseform"
                    :disabled="disabled"
                  >
                    <el-form-item label="视频静音播放" prop="voice">
                      <div class="table-switch">
                        <el-switch
                          v-model="baseform.voice"
                          active-color="#13ce66"
                        >
                        </el-switch>
                      </div>
                    </el-form-item>
                    <el-form-item label="列表动画模式" prop="animationType">
                      <el-select
                        v-model="baseform.animationType"
                        placeholder="请选择列表动画模式"
                        style="width: 80%"
                      >
                        <el-option
                          v-for="item in animationTypeList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="列表轮播间隔" prop="time">
                      <div class="df">
                        <el-input
                          v-model="baseform.time"
                          style="width: 80%"
                        ></el-input>
                        <span style="margin-left: 8px">秒</span>
                      </div>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <div>
                <h3>
                  <span>视频配置</span>
                </h3>
                <el-button
                  class="bgc-bv"
                  style="margin-bottom: 20px"
                  @click="doadd"
                  >新增视频</el-button
                >
                <div>
                  <el-table
                    ref="dialogTable"
                    :data="videoListCopy"
                    :height="300"
                    size="small"
                    tooltip-effect="dark"
                    style="width: 60%"
                    stripe
                    border
                    :header-cell-style="tableHeader"
                  >
                    <el-table-column
                      label="课件名称"
                      align="center"
                      prop="kpointName"
                    >
                    </el-table-column>
                    <el-table-column
                      label="视频时长"
                      align="center"
                      prop="kpointDuration"
                    >
                      <template slot-scope="scope">
                        {{ getTime(scope.row.kpointDuration) || "--" }}
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="140px">
                      <div slot-scope="scope" class="df">
                        <el-button
                          style="padding: 0 5px"
                          type="text"
                          size="mini"
                          @click="doDel(scope.$index)"
                          >删除</el-button
                        >
                        <el-button
                          style="padding: 0 5px"
                          type="text"
                          size="mini"
                          :disabled="scope.$index == 0"
                          @click="doMove('up', scope.$index, scope.row)"
                          >上移</el-button
                        >
                        <el-button
                          style="padding: 0 5px"
                          type="text"
                          size="mini"
                          :disabled="scope.$index == videoListCopy.length - 1"
                          @click="doMove('down', scope.$index, scope.row)"
                          >下移</el-button
                        >
                      </div>
                    </el-table-column>
                    <Empty slot="empty" />
                  </el-table>
                </div>
              </div>
              <div class="btn-box">
                <el-button @click="doCancel" class="bgc-bv">取 消</el-button>
                <el-button class="bgc-bv" @click="doEditSave">确 定</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVideo"
      top="5%"
      width="70%"
      :center="true"
      :before-close="doClose"
      title="添加视频"
    >
      <VideoList @videoBack="videoBack" />
    </el-dialog>
  </div>
</template>
<script>
import VideoList from "@/views/largeScreenDisplay/pupop/videoList";
export default {
  name: "lgScreenSet",
  components: {
    VideoList,
  },
  data() {
    let validateTime = (rule, value, callback) => {
      console.log(value);
      let reg = /^[1-9]\d*$/;
      if (!reg.test(value)) {
        callback(new Error("时间间隔为正整数"));
      } else {
        callback();
      }
    };
    return {
      dialogVideo: false,
      disabled: false,
      tableHeader: {
        background: "#5C6BE8",
        color: "#fff",
        borderColor: "#5C6BE8",
      },
      // 基本信息
      baseform: {
        voice: true,
        animationType: "10",
        time: "5",
      },
      rules: {
        voice: [{ required: true, message: "", trigger: "change" }],
        animationType: [
          { required: true, message: "请选择动画模式", trigger: "blur" },
        ],
        time: [
          { required: true, validator: validateTime, trigger: "[blur,change]" },
        ],
      },
      animationTypeList: [], // 动画模式
      videoList: [],
      videoListCopy: [],
    };
  },
  created() {
    this.init();
  },
  computed: {},
  methods: {
    init() {
      const listObj = this.$setDictionary("LIST_ANIMATON", "list");
      this.animationTypeList = [];
      for (const key in listObj) {
        this.animationTypeList.push({
          value: key,
          label: listObj[key],
        });
      }
      this.query();
    },

    //  新增
    doadd() {
      this.dialogVideo = true;
    },
    //
    doEditSave() {
      if (!this.videoListCopy.length) {
        this.$message({
          type: "warning",
          message: "视频不能为空",
        });
        return;
      }
      let elem = this.$refs.baseform;
      elem.validate((valid) => {
        if (valid) {
          let params = {
            listLoopSpace: this.baseform.time,
            listAnimation: this.baseform.animationType,
            videoMutePlay: this.baseform.voice,
            compId: this.$route.query.compId,
          };
          let videos = [];
          console.log(this.videoListCopy);
          this.videoListCopy.forEach((el, index) => {
            videos.push({
              // configId: 0,
              // configVideoId: el.kpointVideoId,
              kpointDuration: el.kpointDuration,
              kpointId: el.kpointId,
              kpointKey: el.kpointVideoId || el.kpointKey,
              kpointName: el.kpointName,
              sortIndex: index,
            });
          });
          params.videos = videos;
          this.$post("/largescreen/ls-config/modify", params).then((res) => {
            // ;
            this.$message({
              type: "success",
              message: "修改成功",
            });
            setTimeout(() => {
              this.$router.push({
                path: "/web/lgScreenSetList",
              });
            }, 1500);
          });
        }
      });
    },
    // 查询配置
    query() {
      this.$post("/largescreen/ls-config/query", {
        compId: this.$route.query.compId,
      }).then((res) => {
        // ;
        this.baseform.time = res.data.listLoopSpace;
        this.baseform.animationType = res.data.listAnimation;
        this.baseform.voice = res.data.videoMutePlay;
        if (res.data.videos) {
          this.videoListCopy = [...res.data.videos, ...this.videoListCopy];
        }
      });
    },
    // 添加视频
    doMove(stu, ind, row) {
      if (stu == "up") {
        let temp = this.videoListCopy[ind - 1];
        this.$set(this.videoListCopy, ind - 1, this.videoListCopy[ind]);
        this.$set(this.videoListCopy, ind, temp);
      } else {
        let i = this.videoListCopy[ind + 1];
        this.$set(this.videoListCopy, ind + 1, this.videoListCopy[ind]);
        this.$set(this.videoListCopy, ind, i);
      }
    },
    doDel(ind) {
      // if (!this.videoListCopy[ind].flag) {
      //   this.delIds.push(this.videoListCopy[ind].kpointId);
      // }
      this.videoListCopy.splice(ind, 1);
    },
    // 视频选择完成
    videoBack(row) {
      // 添加视频
      this.videoListCopy = [row, ...this.videoListCopy];
      ;
      this.dialogVideo = false;
    },
    doCancel() {
      this.$router.push({
        path: "/web/lgScreenSetList",
      });
    },
    // 转换视频时间
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
  },
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
<style lang="less">
.addlectuer {
  .addlist {
    h3 {
      display: flex;
      border-left: 5px solid rgb(92, 107, 232);
      padding: 0;
      span {
        margin-left: 20px;
      }
      margin-bottom: 30px;
    }
    h3::before {
      content: "";
      border: 0;
    }
  }
}

.zsBtn {
  margin-left: 20px;
  button {
    height: 40px;
  }
}
.form-box {
  padding: 10px 20px;
  .form {
    width: 70%;
    padding: 20px;
  }
  .form-bg {
    background-color: rgb(247, 248, 253);
  }
}
.btn-box {
  padding: 20px 20%;
  display: flex;
  justify-content: space-around;
  width: 70%;
  button {
    padding: 12px 30px;
  }
}
.table-switch {
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}
</style>
