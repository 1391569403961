<template>
  <section class="CourseTablekponit">
    <div class="operationControl" style="border: 0">
      <div class="searchbox">
        <div title="课件名称" class="searchboxItem">
          <span class="itemLabel">课件名称:</span>
          <el-input
            clearable
            v-model="kpointName"
            type="text"
            size="small"
            placeholder="请输入课件名称"
          />
        </div>
        <span title="培训类型" class="searchboxItem ci-full flexcc">
          <span class="itemLabel">培训类型:</span>
          <tree
            ref="tree"
            @eventBtn="childBack"
            :ruleForm="ruleForm"
            size="small"
            @clearParams="clearParams"
            :modal="false"
            typeStu
          />
        </span>
        <!-- <div title="课程来源" class="searchboxItem">
          <span class="itemLabel" >课程来源:</span>
          <el-select size="small" v-model="courseOrigin" clearable placeholder="请选择">
            <el-option
              v-for="item in courseOriginList"
              :key="'courseOrigin' + item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div> -->
        <div title="资源提供者" class="searchboxItem ci-full">
          <span class="itemLabel">资源提供者:</span>
          <el-select
            size="small"
            v-model="compId"
            remote
            :remote-method="getCompanyList"
            filterable
            clearable
            placeholder="请至少输入两个字搜索"
          >
            <el-option
              v-for="item in CompanyList"
              :key="item.compId"
              :label="item.compName"
              :value="item.compId"
            ></el-option>
          </el-select>
        </div>
        <div>
          <el-button class="bgc-bv" @click="getData()">查询</el-button>
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
          border
        >
          <el-table-column label width="35px" align="center">
            <template slot-scope="scope">
              <el-radio v-model="radio" :label="scope.row" />
            </template>
          </el-table-column>
          <el-table-column
            label="课件名称"
            align="center"
            show-overflow-tooltip
            prop="kpointName"
          />
          <el-table-column
            label="培训类型"
            align="center"
            show-overflow-tooltip
            prop="trainTypeNamePath"
          />
          <el-table-column
            label="视频时长"
            align="center"
            show-overflow-tooltip
            width="100"
            prop="kpointDuration"
          >
            <template slot-scope="scope">
              {{ getTime(scope.row.kpointDuration) || "--" }}
            </template>
          </el-table-column>
          <el-table-column
            label="资源提供者"
            align="center"
            show-overflow-tooltip
            prop="compName"
          />
          <el-table-column label="操作" align="center" width="60">
            <div slot-scope="scope">
              <el-button
                style="padding: 0 5px"
                type="text"
                size="mini"
                @click="showVideo(scope.row)"
                >预览</el-button
              >
            </div>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <span class="dialog-footer flexdcc" style="padding: 15px">
      <div>
        <el-button class="bgc-bv" @click="dialogClose">取消</el-button>
        <el-button class="bgc-bv" @click="doOk">确定</el-button>
      </div>
    </span>
    <!-- 视频 -->
    <el-dialog
      title="11"
      :visible.sync="dialogVideoView"
      width="60%"
      :before-close="handleClose"
      top="1%"
      :modal="false"
    >
      <!-- <player_2 :videoId="videoId" :videoTime="videoTime" v-if="videoType" /> -->
      <player :videoId="videoId" :videoTime="videoTime" />
    </el-dialog>
  </section>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import player from "@/components/player";
import { mapGetters } from "vuex";
export default {
  name: "courseTable",
  components: {
    Empty,
    PageNum,
    tree,
    player,
  },
  mixins: [List],
  data() {
    return {
      dialogVideoView: false,
      CompanyList: [], // 资源提供者
      compId: "",
      kpointName: "",
      traintype: "",
      courseOrigin: "",
      courseOriginList: [],
      radio: "",
      props: {
        value: "id",
        label: "label",
        emitPath: false,
      },
      params: {}, // tree 参数
      ruleForm: {
        Trainingtype: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      traintypeList: "getTraintype",
    }),
  },
  created() {},
  methods: {
    // 预览
    showVideo(data) {
      let _this = this;
      //单元测试打开是题库
      if (data.kpointVideoId) {
        _this
          .$post("/biz/courseware/auth", {
            videoId: data.kpointVideoId,
          })
          .then((result) => {
            _this.videoId = data.kpointVideoId;
            _this.videoType = result.data.trySee;
            _this.videoTime = result.data.duration;
            _this.videotitle = data.label;
            _this.dialogVideoView = true;
          });
      }
      // if (!data.list[0].kpointVideoId && data.list[0].paperId) {
      //   this.showPaper(data.list[0]);
      // }
    },
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    tinit() {
      this.getCourseOriginList();
      this.init();
    },
    getCourseOriginList() {
      const courseOriginList = this.$setDictionary("COURSEORGIN", "list");
      const list = [];
      for (const key in courseOriginList) {
        list.push({
          value: key,
          label: courseOriginList[key],
        });
      }
      this.courseOriginList = list;
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        auditState:"30" // 已通过审核的
      };
      // if (this.courseOrigin) {
      //   params.kpointOrigin = this.courseOrigin;
      // }
      if (this.kpointName) {
        params.kpointName = this.kpointName;
      }
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.radio = "";
      this.doFetch({
        url: "/run/courseware/listPage",
        params,
        pageNum,
      });
    },

    getTableHeight(opDom = true, page = true) {
      let tHeight = 740 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    // 确定
    doOk() {
      console.log(13);
      const { radio } = this;
      console.log(radio);
      // return;
      if (radio) {
        this.$emit("videoBack", radio);

      } else {
        this.$message({
          message: "请选择要关联的课件",
          type: "warning",
        });
      }
    },
    dialogClose() {
      this.$emit("videoBack");
      // this.params = {};
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
  },
  watch: {
    compOwnerId: function (val) {
      if (val == "") {
        this.CompanyList = [];
      }
    },
  },
};
</script>
<style lang="less" scoped>
.CourseTablekponit {
  .el-radio {
    .el-radio__label {
      display: none;
    }
    .el-radio__inner {
      width: 18px;
      height: 18px;
      border-color: #777;
    }
    .el-radio__inner:hover {
      border-color: #5c6be8;
    }
    .el-radio__input.is-checked .el-radio__inner {
      border-color: #5c6be8;
      background-color: #5c6be8;
    }
  }
}
</style>

